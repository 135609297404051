import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"

const Katalog28 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Friedrich Hölderlin und Susette Gontard: Ein Briefwechsel" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title:
                  "Friedrich Hölderlin und Susette Gontard: Ein Briefwechsel",
                link: "/k/28",
              },
            ]}
          />
          <PageTitle>
            Friedrich Hölderlin und Susette Gontard: Ein Briefwechsel
          </PageTitle>
          <Stack space={6}>
            <Constrain align="left">
              <AudioPlayer
                src="katalog/22-briefwechsel-01"
                title="Susette Gontard an Friedrich Hölderlin im September/Oktober 1798"
              />
            </Constrain>
            <Constrain align="right">
              <AudioPlayer
                src="katalog/22-briefwechsel-02"
                title="Friedrich Hölderlin an Susette Gontard im April 1799"
              />
            </Constrain>
            <Constrain align="left">
              <AudioPlayer
                src="katalog/22-briefwechsel-03"
                title="Susette Gontard an Friedrich Hölderlin 1798/99"
              />
            </Constrain>
            <Constrain align="right">
              <AudioPlayer
                src="katalog/22-briefwechsel-04"
                title="Friedrich Hölderlin an Susette Gontard Ende Juni 1799"
              />
            </Constrain>
            <Constrain align="right">
              <AudioPlayer
                src="katalog/22-briefwechsel-05"
                title="Friedrich Hölderlin an Susette Gontard im September 1799"
              />
            </Constrain>
            <Constrain align="left">
              <AudioPlayer
                src="katalog/22-briefwechsel-06"
                title="Susette Gontard an Friedrich Hölderlin am 14. März 1799"
              />
            </Constrain>
            <Constrain align="right">
              <AudioPlayer
                src="katalog/22-briefwechsel-07"
                title="Friedrich Hölderlin an Susette Gontard im Oktober/ November 1799"
              />
            </Constrain>
            <Constrain align="left">
              <AudioPlayer
                src="katalog/22-briefwechsel-08"
                title="Susette Gontard an Friedrich Hölderlin am 7. Mai 1800"
              />
            </Constrain>
          </Stack>
          <Constrain>
            <Small color="whisper" align="center">
              Gelesen von Chantal Busse und Patrick Suhm
            </Small>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog28
